import React, { useState, useEffect, useContext } from 'react';
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    CustomInput,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Input,
    Table,
    Pagination,
    PaginationItem,
    PaginationLink,
    UncontrolledButtonDropdown,
    Badge,
    CardHeader,
    CardFooter,
} from 'reactstrap';
import { Icon } from '@iconify/react';
import fileDocumentEditOutline from '@iconify/icons-mdi/file-document-edit-outline';
import { SrxApi } from '../api/SrxApi';
import arrowsVertical from '@iconify/icons-carbon/arrows-vertical';
import arrowUp from '@iconify/icons-carbon/arrow-up';
import arrowDown from '@iconify/icons-carbon/arrow-down';
import shieldAccount from '@iconify/icons-mdi/shield-account';
import accountIcon from '@iconify/icons-mdi/account';
import fileDocument from '@iconify/icons-mdi/file-document';
import { SecureForms } from '../data/MediprocitySecureForms';
import { UserContext } from '../contexts/UserContext';

const Forms = (props) => {
    const [userPharmacies, setUserPharmacies] = useState(null);

    const [userFacilities, setUserFacilities] = useState(null);

    const [faciStates, setFaciStates] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');

    const [activeFaci, setActiveFaci] = useState(null);

    const [selectedFaciStates, setSelectedFaciStates] = useState([]);


    useEffect(() => {
        const query = new URLSearchParams(props.location.search);

        let faciID = query.get('faciID');

        if (faciID) {
            setActiveFaci(faciID);
        } else {
            setActiveFaci(null);
        }
    }, [props.location.search]);

    useEffect(() => {
        SrxApi.get('/pharmacies').then((res) => {
            setUserPharmacies(res.data.pharmacies);
        });
    }, []);

    useEffect(() => {
        SrxApi.get('/facilities').then((res) => {
            setUserFacilities(res.data.facilities);

            let uniqueStates = [...new Set([...res.data.facilities.map(({ facState }) => facState).filter((x) => x)])];

            setFaciStates(uniqueStates);
        });
    }, []);

    function sortByField(array) {
        switch (sortBy.sortField) {
            case 'facName':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facName < b.facName ? -1 : 1));
                else return array.sort((a, b) => (a.facName > b.facName ? -1 : 1));
            case 'ttlPrice':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.ttlPrice < b.ttlPrice ? -1 : 1));
                else return array.sort((a, b) => (a.ttlPrice > b.ttlPrice ? -1 : 1));
            // case 'facName':
            //     if(sortBy.sortDirection === 'asc')
            //         return array.sort((a, b) => a.facName > b.facName ? -1 : 1)
            //     else
            //         return array.sort((a, b) => a.facName < b.facName ? -1 : 1)
            case 'facState':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facState < b.facState ? -1 : 1));
                else return array.sort((a, b) => (a.facState > b.facState ? -1 : 1));
            case 'facID':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.facID < b.facID ? -1 : 1));
                else return array.sort((a, b) => (a.facID > b.facID ? -1 : 1));
            case 'pharmID':
                if (sortBy.sortDirection === 'asc') return array.sort((a, b) => (a.pharmID < b.pharmID ? -1 : 1));
                else return array.sort((a, b) => (a.pharmID > b.pharmID ? -1 : 1));
        }
    }

    const SortCarets = () => (
        <>
            {sortBy.sortDirection === 'asc' ? (
                <Icon icon={arrowUp} color="black" />
            ) : (
                <Icon icon={arrowDown} color="black" />
            )}
        </>
    );

    const [sortBy, setSortBy] = useState({
        sortField: 'facName',
        sortDirection: 'asc',
    });

    function onClickSort(field) {
        if (field === sortBy.sortField) {
            setSortBy({
                ...sortBy,
                sortDirection: sortBy.sortDirection === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSortBy({
                sortField: field,
                sortDirection: 'asc',
            });
        }
    }

    function searchFacilities(array) {
        array.sort((a, b) => (a.name < b.name ? -1 : 1));
        if (searchTerm) {
            let results = array.filter(
                (faci) =>
                    faci.facID.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                    faci.facName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            );
            return results;
        } else {
            return array;
        }
    }

    function returnFullStateName(state) {
        switch (state.toLowerCase()) {
            case 'ct':
                return 'Connecticut';
            case 'fl':
                return 'Florida';
            case 'ky':
                return 'Kentucky';
            case 'md':
                return 'Maryland';
            case 'nj':
                return 'New Jersey';
            case 'ny':
                return 'New York';
            case 'oh':
                return 'Ohio';
            case 'pa':
                return 'Pennsylvania';
            default:
                return state;
        }
    }

    return (
        <>
            <h2>Secure Forms</h2>
            <hr />
            {faciStates ? (
                <>
                    <Row className="justify-content-between align-items-center">
                        <Col xs={'auto'}>
                            <h3>Filter by State</h3>
                        </Col>
                        {!!selectedFaciStates.length && (
                            <Col xs={'auto'}>
                                <h5>
                                    <b>Selected:</b> <b>{selectedFaciStates.length}</b> of <b>{faciStates.length}</b>
                                </h5>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-2" style={{ borderTop: '2px solid #eef2f7', width: '350px' }}></div>
                        </Col>
                    </Row>
                    <Row>
                        {faciStates
                            .sort((a, b) => (a > b ? 1 : -1))
                            .map((state) => (
                                <Col style={{ minWidth: '140px', maxWidth: '140px' }}>
                                    <Card
                                        className="pharm-card text-center py-2"
                                        style={{
                                            outline:
                                                selectedFaciStates.indexOf(state) !== -1 ? '4px solid #AC95D0' : '',
                                        }}
                                        onClick={() => {
                                            if (selectedFaciStates.indexOf(state) === -1) {
                                                setSelectedFaciStates([...selectedFaciStates, state]);
                                            } else {
                                                setSelectedFaciStates(selectedFaciStates.filter((x) => x !== state));
                                            }
                                        }}>
                                        <i className="uil uil-medkit text-muted font-15"></i>
                                        <h3>
                                            <span>{state}</span>
                                        </h3>
                                        <p className="text-mPAuted font-10 mb-0">
                                            {returnFullStateName(state).toUpperCase()}
                                        </p>
                                    </Card>
                                </Col>
                            ))}
                    </Row>
                </>
            ) : (
                <Row style={{ height: '200px' }}>
                    <div className="page-loading-loader-div w-100 d-flex justify-content-center align-items-center">
                        <div>
                            <h4>Loading States...</h4>
                            <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </Row>
            )}
            <hr className="mt-2 mb-3" />
            {userFacilities ? (
                <>
                    <h3>Facilities</h3>
                    <Row>
                        <Col>
                            <div className="mb-2" style={{ borderTop: '2px solid #eef2f7', width: '350px' }}></div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className="mb-2 d-flex justify-content-between">
                                <Col className="d-flex align-items-center">
                                    <span className="mr-1">Total Facilities:</span>{' '}
                                    <b>
                                        {
                                            sortByField(
                                                searchFacilities(
                                                    userFacilities.filter((faci) =>
                                                        selectedFaciStates.length
                                                            ? selectedFaciStates.indexOf(faci.facState) !== -1
                                                            : true
                                                    )
                                                )
                                            ).length
                                        }
                                    </b>
                                </Col>
                                <Col className="text-right" md={6} lg={4}>
                                    <FormGroup
                                        className="d-flex mb-0 align-items-center"
                                        style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                        <Label className="mr-2 mb-0">
                                            <b>Search facilities:</b>
                                        </Label>
                                        <Input
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}></Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table className="mb-0" hover>
                                <thead className="bg-light">
                                    <tr>
                                        <th onClick={() => onClickSort('facID')} className="th-hover">
                                            ID{' '}
                                            {sortBy.sortField === 'facID' ? (
                                                <SortCarets />
                                            ) : (
                                                <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                            )}
                                        </th>
                                        <th onClick={() => onClickSort('facName')} className="th-hover">
                                            Name{' '}
                                            {sortBy.sortField === 'facName' ? (
                                                <SortCarets />
                                            ) : (
                                                <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                            )}
                                        </th>
                                        <th onClick={() => onClickSort('pharmID')} className="th-hover">
                                            Pharmacy ID{' '}
                                            {sortBy.sortField === 'pharmID' ? (
                                                <SortCarets />
                                            ) : (
                                                <Icon icon={arrowsVertical} style={{ opacity: '0.6' }} />
                                            )}
                                        </th>
                                        <th>Role</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortByField(
                                        searchFacilities(
                                            userFacilities.filter((faci) =>
                                                selectedFaciStates.length
                                                    ? selectedFaciStates.indexOf(faci.facState) !== -1
                                                    : true
                                            )
                                        )
                                    ).map((facility, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => {
                                                    const newLocation = {
                                                        pathname: props.location.pathname,
                                                        search: `faciID=${facility.facID}`,
                                                    };

                                                    props.history.push(newLocation);
                                                }}>
                                                <th scope="row" style={{ verticalAlign: 'middle' }}>
                                                    {facility.facID}
                                                </th>
                                                <td style={{ verticalAlign: 'middle' }}>{facility.facName}</td>
                                                <td style={{ verticalAlign: 'middle' }}>{facility.pharmID}</td>
                                                <td style={{ verticalAlign: 'middle' }}>
                                                    <b>
                                                        <i>{facility.userRole}</i>
                                                    </b>
                                                </td>
                                                <td>
                                                    <Button
                                                        className="link-button p-0"
                                                        color="link"
                                                        style={{ color: '#6c757d', fontSize: '26px' }}
                                                        onClick={(e) => {
                                                            e.stopPropagation(e);
                                                            const query = new URLSearchParams(props.location.search);

                                                            const newLocation = {
                                                                pathname: props.location.pathname,
                                                                search: `faciID=${facility.facID}`,
                                                            };

                                                            props.history.push(newLocation);
                                                        }}>
                                                        <Icon icon={fileDocument} />
                                                    </Button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                    <FormDetailModal
                        facility={
                            activeFaci
                                ? userFacilities.find(
                                      (faci) => faci.facID.toLocaleLowerCase() === activeFaci.toLocaleLowerCase()
                                  )
                                : null
                        }
                        toggle={() => {
                            const newLocation = {
                                pathname: props.location.pathname,
                            };

                            props.history.push(newLocation);
                        }}
                    />
                </>
            ) : (
                <Row style={{ height: '200px' }}>
                    <div className="page-loading-loader-div w-100 d-flex justify-content-center align-items-center">
                        <div>
                            <h4>Loading Facilities...</h4>
                            <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </Row>
            )}
        </>
    );
};

const FormDetailModal = ({ pharmacy, facility, toggle }) => {
    const { user } = useContext(UserContext);
    

    let mediprocityFormsBaseUrl = 'https://secure-forms.mediprocity.com/forms/';

    function returnFormState(pharmID) {
        if (pharmID.toLowerCase() === 'sprx') return 'SPRX';
        if (pharmID.toLowerCase() === 'upny') return 'UPNY';
        if (pharmID.toLowerCase() === 'ohio') return 'OHIO';
        if (pharmID.toLowerCase() === 'parx') return 'PARX';
        if (pharmID.toLowerCase() === 'fln') return 'FLN';
        if (pharmID.toLowerCase() === 'fll') return 'FLL';
        if (pharmID.toLowerCase() === 'flw') return 'FLW';
        if (pharmID.toLowerCase() === 'alrx') return 'ALRX';
        if (pharmID.toLowerCase() === 'txc') return 'TXC';

    }

    const renderForm = (form) => {
        const link = formLink(form);
    if (form.label === 'covid vaccine independent contractor') {
        // Use formLink to check if the form should be rendered or hidden
        return formLink(form) !== null;
    }
    return true;

    };

    const formLink = (form) => {
        // SPRX form links
        if (form.label === 'OTC Override Authorization Form') {
            return `${mediprocityFormsBaseUrl}${form.id}`;
        } 
        if (form.label === 'covid vaccine independent contractor' && facility.facState?.toLowerCase() !== 'nj') {
            return null;
        }

        if (form.label === 'Out on Pass Medication Form') {
            return `${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&facility=${encodeURIComponent(
                facility.facName
            )}&nurse_name=${encodeURIComponent(user.displayName)}&today_date=${encodeURIComponent(
                new Date().toLocaleDateString('en-US')
            )}&type_sign_1=${encodeURIComponent(user.displayName)}&date_1=${encodeURIComponent(
                new Date().toLocaleDateString('en-US')
                )}`;

        } else if (form.label === 'Vaccine Request Form') {
            return `${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&facility_name=${encodeURIComponent(
                facility.facName
            )}&date=${encodeURIComponent(
                new Date().toLocaleDateString('en-US')
            )}&individual_ordering=${encodeURIComponent(user.displayName)}&date_1=${encodeURIComponent(
                new Date().toLocaleDateString('en-US')
                )}`;

        } else if (form.label === 'Reorder Medication Form') {
            return `${mediprocityFormsBaseUrl}${form.id}?fill_from_url=true&facility=${encodeURIComponent(
                facility.facName
            )}&facility_rep=${encodeURIComponent(user.displayName)}&date=${encodeURIComponent(
                new Date().toLocaleDateString('en-US')
                )}`;

        } else if (form.label === 'covid vaccine independent contractor') {
            return (
                `${mediprocityFormsBaseUrl}${
                    form.id
                }?fill_from_url=true&independent_contractor_agreement_date=${encodeURIComponent(
                    new Date().toLocaleDateString('en-US')
                )}&contractor=${encodeURIComponent(facility.facName)}` +
                `&state_name_2_checkbox=${encodeURIComponent(
                    facility.facState?.toLowerCase() === 'nj' ? 'Yes' : 'No'
                )}` +
                `&state_name_2=${encodeURIComponent(
                    facility.facState?.toLowerCase() === 'nj' ? '' : facility.facState
                )}` +
                `&state_name_3_checkbox=${encodeURIComponent(
                    facility.facState?.toLowerCase() === 'nj' ? 'Yes' : 'No'
                )}` +
                `&state_name_3=${encodeURIComponent(
                    facility.facState?.toLowerCase() === 'nj' ? '' : facility.facState
                )}` +
                `&facility_name=${encodeURIComponent(facility.facName)}` +
                `&date=${encodeURIComponent(new Date().toLocaleDateString("en-US"))}` +
                `&type_sign_1=${encodeURIComponent(user.displayName)}` +
                `&name=${encodeURIComponent(user.displayName)}` +
                `&title=${encodeURIComponent(user.hasOwnProperty('title') ? user.title : '')}`
            );
        }
    };

    return (
        <Modal centered isOpen={facility} toggle={toggle}>
            {facility ? (
                <>
                    <ModalHeader
                        className="gradient-modal"
                        toggle={toggle}
                        style={{
                            background: 'linear-gradient(90deg, #452f69 0%, #452f69 50%, #000 90%)',
                            color: 'white',
                        }}>
                        {facility.facName}
                    </ModalHeader>
                    <ModalBody>
                        <>
                            <Row>
                                <Col>
                                    <p>
                                        Facility ID: <b>{facility.facID}</b>
                                    </p>
                                    <p>
                                        Facility Name: <b>{facility.facName}</b>
                                    </p>
                                    <p>
                                        Pharmacy ID: <b>{facility.pharmID}</b>
                                    </p>
                                </Col>
                            </Row>
                            <hr className="my-0" />
                            <Row>
                                <Col>
                                    <h3>Forms: </h3>
                                </Col>
                            </Row>
                            <Row>
                                {SecureForms[returnFormState(facility.pharmID)].map((form) => (
                                    <Col md={6} style={{ minWidth: '200px', display: renderForm(form) ? 'block' : 'none' }}>
                                        {renderForm(form) && (
                                        <a href={formLink(form)} target="_blank" rel="noopener noreferrer">
                                            <Card>
                                                <CardBody className="d-flex justify-content-center align-items-center">
                                                    <Icon
                                                        icon={fileDocumentEditOutline}
                                                        color="#D6E0EC"
                                                        width="120"
                                                        height="120"
                                                    />
                                                </CardBody>
                                                <CardFooter
                                                    className="d-flex align-items-center justify-content-center"
                                                    style={{
                                                        overflow: 'hidden',
                                                        maxHeight: '84px',
                                                        minHeight: '84px',
                                                    }}>
                                                    <h4 className="text-center" style={{ textTransform: 'capitalize' }}>
                                                        {form.label}
                                                    </h4>
                                                </CardFooter>
                                            </Card>
                                        </a>
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </>
                    </ModalBody>
                </>
            ) : (
                ''
            )}
        </Modal>
    );
};

export default Forms;
